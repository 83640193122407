<template>
  <div class="BodyParts content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="名称">
              <el-input
              clearable
                placeholder="输入名称搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择" @change="handleSearch">
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="类型编号" prop="MarkType"></el-table-column>
        <el-table-column label="护理周期名称" prop="Name"></el-table-column>
        <!-- <el-table-column
          label="备注"
          prop="Remark"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column label="有效性" prop="Active">
          <template slot-scope="scope">{{
            scope.row.Active ? "有效" : "无效"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="deleter(scope.row)"
              v-if="isDelete"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12" v-if="dialogtype == 2">
              <el-form-item label="评分编号" prop="MarkType">
                <el-input
                disabled
                  clearable
                  v-model="ruleForm.MarkType"
                  placeholder="请输入评分编号"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" prop="Name">
                <el-input
                  clearable
                  v-model="ruleForm.Name"
                  placeholder="请输入名称"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dialogtype == 2">
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
                <el-radio v-model="ruleForm.Active" :label="true"
                  >有效</el-radio
                >
                <el-radio v-model="ruleForm.Active" :label="false"
                  >无效</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Instrument/MarkType";
import permission from "@/components/js/permission.js";
export default {
  name: "MarkType",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: "", //删除权限
      dialogtype: 1,
      dialogVisible: false,
      searchName: "",
      tableData: [],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        Active: true,
      },
      rules: {
        MarkType: [{ required: true, message: "请输入评分编号", trigger: "blur" }],
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Active:[{required:true}]
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**路由守卫 */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "检测项目");
    });
  },
  /**  方法集合  */
  methods: {
    handleSearch() {
     var that = this;
     var params = {
Active:that.Active,
Name:that.searchName,
PageNum:that.paginations.page
     }
      API.custMarkType(params).then(res=>{
        if (res.StateCode == 200 ) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        }else{
          that.$message.error(res.Message);
        }
      })
    },
    addParts() {
      this.dialogtype = 1;
      this.dialogVisible = true;
    },
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editor(row) {
      const { Active, Name, MarkType } = row;
      this.ruleForm = {
         Active,
        MarkType,
        Name,
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
    },
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    closeDialog() {
      this.ruleForm = {
         Active: true,
      };
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        }
      });
    },
    createParts() {
      var that = this;
      API.create(this.ruleForm).then(res=>{
        if (res.StateCode == 200 ) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        }else{
          that.$message.error(res.Message);
        }
      })
    },
    updateParts() {
       var that = this;
      API.update(this.ruleForm).then(res=>{
        if (res.StateCode == 200 ) {
          that.$message.success("已修改!");
          this.closeDialog();
          that.handleSearch();
        }else{
          that.$message.error(res.Message);
        }
      })
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "检测项目"
    );
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
