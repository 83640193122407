import * as API from '@/api/index'

export default {
    custMarkType:params =>{
        return API.POST('api/custMarkType/all',params)
    },
    create:params =>{
        return API.POST('api/custMarkType/create',params)
    },
    update:params =>{
        return API.POST('api/custMarkType/update',params)
    },
}